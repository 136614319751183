<template>
  <th @click="sorted(value)">
    <div class="d-flex align-items-center justify-content-center">
      {{ text }}
      <i class="icon icon-arrow-up-3 sort-arrow" :class="value"></i>
    </div>
  </th>
</template>

<script>
export default {
  props: {
    data: Array,
    value: String,
    text: String,
  },
  methods: {
    sorted(value) {
      this.$emit("sortColumn", this.sort(value));
    },

    sort(value) {
      let current = document.querySelector(`.sort-arrow.${value}`);
      current.classList.toggle("active");
      document
        .querySelectorAll(`.sort-arrow:not(.${value})`)
        .forEach((arrow) => arrow.classList.remove("active"));
      if (current.classList.contains("active")) {
        this.data.sort((a, b) => {
          
          return a[value].replaceAll(".","").toString().localeCompare(b[value].replaceAll(".","").toString(), undefined, {
              numeric: true,
              sensitivity: 'base'
            });
        });
      } else {
        this.data.reverse((a, b) => {
          return a[value].replaceAll(".","").toString().localeCompare(b[value].replaceAll(".","").toString(), undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
      }
      return this.data;
    },
  },
};
</script>
<style scoped>
.sort-arrow.active {
  transform: rotate(180deg);
}
.sort-arrow {
    font-size: 1rem;
    margin-left: 15px;
}
</style>