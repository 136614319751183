<template>
  <button @click="exportExcel" class="btn btn-filled-grey color-white">
    <slot></slot>
    Excel İndir
    <i class="icon icon-document-download ml-10"></i>
  </button>
</template>

<script>
import XLSX from "xlsx/xlsx";

export default {
  name: "vue-excel-xlsx",
  props: {
    thead: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    fileName: {
      type: String,
      default: "excel",
    },
    sheetName: {
      type: String,
      default: "SheetName",
    },
    fileType: {
      type: String,
      default: "xlsx",
      validator: (val) => ["xlsx", "xls"].includes(val),
    },
  },

  methods: {
    exportExcel() {
      let createXLSLFormatObj = [];
      let newXlsHeader = [];
      let vm = this;
      if (vm.thead.length === 0) {
        return;
      }
      if (vm.data.length === 0) {
        return;
      }
      vm.thead.map((column) => {
        if (column.exports != false) {
          newXlsHeader.push(column.label);
        }
      });

      createXLSLFormatObj.push(newXlsHeader);
      vm.data.map((value) => {
        let innerRowData = [];
        vm.thead.map((val) => {
          let fieldValue = value[val.value];
          if (val.exports != false) {
            if (val.value.split(".").length > 1) {
              fieldValue = this.getNestedValue(value, val.value);
            }
            if (val.dataFormat && typeof val.dataFormat === "function") {
              innerRowData.push(val.dataFormat(fieldValue));
            } else {
              innerRowData.push(fieldValue);
            }
          }
        });
        createXLSLFormatObj.push(innerRowData);
      });

      let fileName = vm.fileName + "." + vm.fileType;

      let ws_name = vm.sheetName;

      let wb = XLSX.utils.book_new(),
        ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(wb, fileName);
    },
    getNestedValue(object, string) {
      string = string.replace(/\[(\w+)\]/g, ".$1");
      string = string.replace(/^\./, "");
      let a = string.split(".");
      for (let i = 0, n = a.length; i < n; ++i) {
        let k = a[i];
        if (k in object) {
          object = object[k];
        } else {
          return;
        }
      }
      return object;
    },
  },
};
</script>
