import axios from 'axios'
import router from '../../router'

const state = () => ({
    theme_mode: "light",
    lang: "",
    region: "",
    breadcrumps:[]
})


const getters = {
    getBreadcrumps(state){
        return state.breadcrumps
    }
};


const actions = {

};

const mutations = {
    addToBreadcrumps(state, items) {
        state.breadcrumps = []
        items.forEach(item => {
            state.breadcrumps.push(item);
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}