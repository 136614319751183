<template>
  <div class="form-group w-icon icon-left mb-0">
    <i class="icon icon-search-normal-1"></i>
    <input
      type="text"
      v-model="search"
      class="form-control"
      :placeholder="placeholder"
      @input="onSearchInput()"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      search: "",
      key: "",
    };
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    placeholder: String,
    cls: String,
    searchKey: String,
    thead: Array,
  },
  methods: {
    onSearchInput() {
      this.$emit("search", this.filteredData);
    },
  },
  computed: {
    filteredData() {
      if (this.search) {
        let searchTerm = String(this.search).toLocaleLowerCase("tr-TR");
        let searchKey = String(this.searchKey);
        if (searchKey == "all") {
          return this.data.filter((item, i) => {
            this.key = this.thead[i];
            String(item[this.key])
              .toLocaleLowerCase("tr-TR")
              .includes(searchTerm);
          });
        } else {
          return this.data.filter((item) => {
            return String(item[searchKey])
              .toLocaleLowerCase("tr-TR")
              .includes(searchTerm);
          });
        }
      } else {
        return this.data;
      }
    },
  },
};
</script>