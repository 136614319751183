<template>
  <div class="modal-wrapper">
    <div class="modal-container">
      <div class="modal-header">
        <button
          @click="closeModal"
          type="button"
          class="btn btn-empty-primary close-button"
        >
        <i class="icon icon-close-circle"></i>
        </button>
      </div>

      <div class="modal-body text-center">
        <i class="icon icon-tick-circle"></i>
        <div class="card-title">{{ doneTitle }}</div>
        <p>{{ doneMsg }}</p>
        <router-link
          :to="link"
          class="btn btn-filled-primary w-100"
          v-if="link"
          >Listeye Dön</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits:["closeModal"],
  props: {
    doneMsg: {
      type: String,
    },
    link: {
      type: String,
    },
    doneIcon: {
      type: String,
    },
    doneTitle: {
      type: String,
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal", this.isCloseModal);
    },
  },
};
</script>

<style>
.close-button {
  position: absolute;
  right: -60px;
  top: 0;
}
</style>