import axios from "axios"
import router from "../../router"
import { jwtDecrypt } from "../jwtHelper.js";
import { getCookie } from "@/core/helpers.js";

const state = () => ({
    isLoggedIn: false,
    access_token: "",
    refresh_token: "",
    throwError: "",
    user: {}

})


const getters = {
    getIsLoggedIn(state) {
        return state.isLoggedIn
    },
    getThrowError(state) {
        return state.throwError
    },
    getUser(state) {
        return state.user
    }
};


const actions = {
    async loginReq({ commit }, payload) {
        await axios.post("user/token/", payload)
            .then(async (response) => {
                state.throwError = ""

                let userId = await jwtDecrypt(response.data.access).user_id

                await commit("setLoggedIn", true)
                await commit("setToken", response.data)

                const user = await axios.get(`user/${userId}/`).then((response) => response.data);

                // const cookieRegion = await getCookie("region");
                // const cookieLanguage = await getCookie("language");


                // if ((user.region && user.language) || (cookieRegion && cookieLanguage)) {
                //     router.push("/admin")
                // }
                // else {
                //     router.push("/admin/region")
                // }

                
                commit("setUser", user)
                
                router.push("/")

            })
            .catch(err => {
                let error = err.response.data ? err.response.data.detail : "Beklenmeyen hata: " + err
                commit("setThrowError", error)
                console.log(err)
            })
    },
};

const mutations = {
    setLoggedIn(state, payload) {
        state.isLoggedIn = payload
    },
    setToken(state, data) {
        state.access_token = data.access
        state.refresh_token = data.refresh

        const decryptedAuth = jwtDecrypt(data.access)

        state.role = decryptedAuth.role
    },
    setThrowError(state, payload) {
        state.throwError = payload
    },
    setUser(state, user) {
        state.user = user
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}