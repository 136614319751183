<template>
  <template v-if="type == 'text'">
    <div class="form-group" :class="className">
      <label for="">{{ label ? label : value }}</label>
      <input
        type="text"
        class="form-control"
        @input="commitText"
        masked="true"
        v-mask="this[mask]"
        v-model="value"
        v-if="mask"
      />
      <input
        type="text"
        class="form-control"
        @input="commitText"
        v-model="value"
        v-else
      />

      <span class="text-info" v-if="textInfo">
        <i class="icon icon-info-circle"></i>
        {{ textInfo }}
      </span>
    </div>
  </template>
  <template v-if="type == 'password'">
    <div class="form-group" :class="className">
      <label for="">{{ label ? label : value }}</label>
      <input
        type="password"
        class="form-control"
        @input="commitText"
        v-model="value"
      />
      <span class="text-info" v-if="textInfo">
        <i class="icon icon-info-circle"></i>
        {{ textInfo }}
      </span>
    </div>
  </template>
  <template v-if="type == 'date'">
    <div class="form-group" :class="className">
      <label for="">{{ label ? label : value }}</label>
      <input
        type="date"
        class="form-control"
        @input="commitText"
        v-model="value"
      />
      <span class="text-info" v-if="textInfo">
        <i class="icon icon-info-circle"></i>
        {{ textInfo }}
      </span>
    </div>
  </template>
  <template v-if="type == 'textarea'">
    <div class="form-group" :class="className">
      <label for="">{{ label ? label : value }}</label>
      <textarea
        v-model="value"
        class="form-control"
        type="text"
        rows="5"
        @input="commitText"
      >
      </textarea>
      <span class="text-info" v-if="textInfo">
        <i class="icon icon-info-circle"></i>
        {{ textInfo }}
      </span>
    </div>
  </template>

  <template v-if="type == 'select'">
    <div class="form-group" :class="className">
      <label for="">{{ label ? label : value }}</label>
      <select v-model="value" class="form-select" @change="commitText">
        <option :value="option" v-for="option in options" :key="option">
          {{ option }}
        </option>
      </select>
      <span class="text-info" v-if="textInfo">
        <i class="icon icon-info-circle"></i>
        {{ textInfo }}
      </span>
    </div>
  </template>
  <template v-if="type == 'selectID'">
    <div class="form-group" :class="className">
      <label for="">{{ label ? label : value }}</label>
      <select v-model="value" class="form-select" @change="commitText">
        <option :value="option.id" v-for="option in options" :key="option">
          {{ option.name }}
        </option>
      </select>
    </div>
  </template>
</template>
<script>
export default {
  emits: ["onChangeText"],
  data() {
    return {
      phone: {
        mask: "0*#########",
        tokens: {
          "#": { pattern: /[0-9]/ },
          "*": { pattern: /[1-9]/ },
        },
      },
    };
  },
  props: {
    value: {
      type: [String, Number, Object],
      default: "",
    },
    type: String,
    options: Array,
    label: String,
    keyName: String,
    textInfo: String,
    className: String,
    mask: String,
  },
  methods: {
    commitText() {
      this.$emit("onChangeText", {
        value: this.value,
        keyName: this.keyName,
      });
    },
  },
};
</script>