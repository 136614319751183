import "bootstrap/dist/css/bootstrap.min.css"
import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import axios from "./interceptors"
import "bootstrap";
import mask from "vue-the-mask";
import env from "./sys.config.js"
import { removeHttp, replaceUrl, removeSpace, downloadFile, setCookie, getCookie } from "@/core/helpers.js"
import Multiselect from "vue-multiselect"

import Table from "@/components/Table.vue";
import Done from "@/components/Done.vue";
import Error from "@/components/Error.vue";
import CustomeModal from "@/components/CustomeModal.vue";
import CodeEditor from "simple-code-editor";
import TextTemplate from "@/components/TextTemplate.vue";
import UploadTemplate from "@/components/UploadTemplate.vue";

import 'iconsax-font-icon/dist/icons.css';

const components = {
    CodeEditor,
    Table,
    Done,
    Error,
    CustomeModal,
    Multiselect,
    TextTemplate,
    UploadTemplate,
}

const app = createApp(App);

for (const component in components) app.component(component, components[component]);

app.config.globalProperties = {
    $axios: axios,
    $env: env,
    removeHttp: removeHttp,
    replaceUrl: replaceUrl,
    removeSpace: removeSpace,
    downloadFile: downloadFile,
    setCookie: setCookie,
    getCookie: getCookie,
}

const useItems = {
    router,
    store,
    mask,
}

for (const useItem in useItems) app.use(useItems[useItem]);


app.mount("#app")