<template>
  <div class="modal-wrapper">
    <div class="modal-container">
      <div class="modal-header">
        <div class="card-title">{{doneTitle}}</div>
      </div>
        
        <div class="modal-body text-center">
          Başarılı bir şekilde {{doneMsg}}
          <div class="done">
            <img src="/media/succes-icon.png" alt="">
          </div>
          <router-link :to="link" class="btn btn-filled-primary w-auto m-auto" v-if="link">Listeye Dön</router-link>
          <button @click="closeModal" type="button" class="btn btn-filled-primary w-auto m-auto" v-else>Kapat</button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        doneMsg:{
            type:String,
        },
        link:{
            type:String,
        },
        doneIcon:{
            type:String
        },
        doneTitle:{
            type:String
        }
    },
    methods: {
    closeModal() {
      this.$emit("closeModal", this.isCloseModal);
    },
  },
}
</script>