<template>
  <div class="drag-title">{{ label ? label : itemName }}</div>
  <div class="form-group mb-30" :class="className">
    <div
      class="d-flex align-items-center "
      @dragover.prevent
      @drop.prevent
    >
      <div class="dragPlace" @drop="dropFile($event, itemName)">
        <button
          type="button"
          @click="toggleEditor"
          class="btn btn-empty-primary minw-auto mb-10 btn-edit-file"
          v-if="edit"
        >
        <i class="icon icon-message-edit"></i>
        </button>
        <div
          class="file-name"
          v-if="alReadyUploadedFileName || formData[itemName]"
        >
          <div class="name-link">
            {{
              formData[itemName]
                ? formData[itemName].name
                : alReadyUploadedFileName.split("/").pop()
            }}
            <img :src="this.$env.stage.API_URL + alReadyUploadedFileName + '?nbr=' +  imgNumber" alt="" class="logo">
            <a
              href="javascript:void(0)"
              @click="this.downloadFile(fileUrls[itemName])"
              class="btn btn-empty-primary download"
              v-if="fileUrls[itemName]"
            >
              <i class="icon icon-document-download"></i>
            </a>
            <a
              href="javascript:void(0)"
              @click="this.downloadFile(this.$env.stage.API_URL + alReadyUploadedFileName)"
              class="btn btn-empty-primary download"
              v-else
            >
            <i class="icon icon-document-download"></i>
            </a>
          </div>
        </div>
        <div class="text-center">
          Dosyayı buraya sürükleyin
          <br />veya <br />
          <i class="icon icon-document-upload" v-if="!itemName"></i>
        </div>
        <button
          @click="focusUpload"
          type="button"
          class="btn btn-bordered-primary mt-15 minw-auto"
        >
          Dosya Seç
        </button>
        <input
          @change="fileUpload($event, itemName)"
          type="file"
          class="file-input"
        />
      </div>
    </div>
    <span class="text-info" v-if="textInfo">
        <i class="icon icon-info-circle"></i>
        {{textInfo}} 
      </span>
  </div>
</template>


<script>
export default {
  name: "UploadTemplate",
  emits:["file-committed","toggleEditor"],
  data() {
    return {
      formData: {},
      fileUrls: {},
      editJson :false,
      editJs:false,
      imgNumber:new Date().getTime()
    };
  },
  props: {
    label: String,
    itemName: String,
    alReadyUploadedFileName: {
      type: [String, Number, Object],
      default: "",
    },
    appId: String,
    storeVersion: String,
    textInfo: String,
    className: String,
    edit:Boolean,
  },
  methods: {
    focusUpload(e) {
      e.target.nextSibling.click();
    },
    fileUpload(e, itemName) {
      this.formData[itemName] = e.target.files[0];
      this.fileUrls[itemName] = URL.createObjectURL(e.target.files[0]);
      e.target.parentNode.classList.add("active");
      this.commitFile();
    },

    dropFile(e, itemName) {
      this.formData[itemName] = e.dataTransfer.files[0];
      this.fileUrls[itemName] = URL.createObjectURL(e.dataTransfer.files[0]);
      e.target.parentNode.classList.add("active");
      this.commitFile();
    },

    commitFile() {
      this.$emit("file-committed", {
        file: this.formData[this.itemName],
        name: this.itemName,
        fileUrl: this.fileUrls[this.itemName]
      });
    },
    toggleEditor(){
      this.$emit("toggleEditor", true)
    }
  },
};
</script>