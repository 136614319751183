import { createStore } from 'vuex'
import authModule from './module/auth'
import themeModule from './module/theme'

const store = createStore({
    modules: {
        auth: authModule,
        theme: themeModule,
    }
})

export default store;