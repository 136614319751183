const env = {
    SITE_BASE: window.location.origin,
    API_VERSION: "v1/",
    integration: {
        API_BASE_URL: "http://localhost:8000/api/",
        API_URL: "http://localhost:8000",
    },
    staging: {
        API_BASE_URL: "http:///api/",
        API_URL: "http:///",
    },
    production: {
        API_BASE_URL: "https://api.console.mapplico.com/api/",
        API_URL: "https://api.console.mapplico.com",
    }
}

export default { stage: env.production, version: env.API_VERSION }