import { createWebHistory, createRouter } from 'vue-router';
import store from '../store'

const adminRoles = [
  "InternalUser",
  "ManagementUser",
  "ITUser",
  "AdminUser"
]
const customerRoles = [
    "CustomerUser"
]

const allRoles = [
  "InternalUser",
  "ManagementUser",
  "ITUser",
  "AdminUser",
  "CustomerUser"
]

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/region",
    name: "Region",
    component: () => import("../views/Region.vue"),
  },
  {
    path: '/',
    redirect: "/",
    meta: {
      requiresAuth: true,
      title: "Anasayfa",
      icon: "home"
    },
    component: () => import("../components/Layout.vue"),
    children: [
      {
        path: "/",
        name: "Dashboard",
        meta: {
          requiresAuth: true,
          title: "",
          icon: "home",
          roles: allRoles,
        },
        component: () => import("../views/Dashboard.vue"),
      },
      {
        path: "apps",
        redirect: "/apps/app-list",
        props: true,
        meta: {
          requiresAuth: true,
          title: "Mağazalar",
          icon: "store",
          roles: allRoles,

        },
        component: () => import("../views/App/Apps.vue"),
        children: [
          {
            path: "app-list",
            name: "Apps",
            props: true,
            meta: {
              requiresAuth: true,
              title: "Uygulamalar",
              icon: "apps",
              roles: allRoles,

            },
            component: () => import("../views/App/AppList.vue"),
          },
          {
            path: "app",
            name: "AppCreate",
            props: true,
            meta: {
              requiresAuth: true,
              title: "Uygulama Ekle",
              icon: "apps",
              roles: adminRoles,
            },
            component: () => import("../views/App/App.vue"),
          },
          {
            path: "app/:id",
            name: "AppEdit",
            props: true,
            meta: {
              requiresAuth: true,
              title: "Uygulama Düzenle",
              icon: "apps",
              roles: adminRoles,
            },
            component: () => import("../views/App/AppDetail.vue"),
          },
        ]
      },      
      {
        path: "users",
        redirect: "/users/user-list",
        props: true,
        meta: {
          requiresAuth: true,
          title: "Kullanıcı Listesi",
          icon: "group",
          roles: adminRoles,
        },
        component: () => import("../views/User/Users.vue"),
        children: [
          {
            path: "user-list",
            name: "UserList",
            props: true,
            meta: {
              requiresAuth: true,
              title: "Kullanıcı Listesi",
              icon: "group",
              roles: adminRoles,
            },
            component: () => import("../views/User/UserList.vue"),
          },
          {
            path: "user",
            name: "User",
            props: true,
            meta: {
              requiresAuth: true,
              title: "Kullancı Ekle",
              icon: "group",
              roles: adminRoles,
            },
            component: () => import("../views/User/User.vue"),
          },
          {
            path: "user/:id",
            name: "UserEdit",
            props: true,
            meta: {
              requiresAuth: true,
              title: "Kullanıcı Güncelle",
              icon: "group",
              roles: adminRoles,
            },
            component: () => import("../views/User/UserDetail.vue"),
          },
        ]
      },
      {
        path: "customers",
        redirect: "/customers/customer-list",
        props: true,
        meta: {
          requiresAuth: true,
          title: "Firma Listesi",
          icon: "store",
          roles: adminRoles,
        },
        component: () => import("../views/Customer/Customers.vue"),
        children: [
          {
            path: "customer-list",
            name: "CustomerList",
            props: true,
            meta: {
              requiresAuth: true,
              title: "Firma Listesi",
              icon: "store",
              roles: adminRoles,
            },
            component: () => import("../views/Customer/CustomerList.vue"),
          },
          {
            path: "customer",
            name: "Customer",
            props: true,
            meta: {
              requiresAuth: true,
              title: "Firma Ekle",
              icon: "store",
              roles: adminRoles,
            },
            component: () => import("../views/Customer/Customer.vue"),
          },
          {
            path: "customer/:id",
            name: "CustomerEdit",
            props: true,
            meta: {
              requiresAuth: true,
              title: "Firma Güncelle",
              icon: "store",
              roles: adminRoles,
            },
            component: () => import("../views/Customer/CustomerDetail.vue"),
          },
        ]
      },
      {
        path: "platform-accounts",
        redirect: "/platform-accounts/platform-account-list",
        props: true,
        meta: {
          requiresAuth: true,
          title: "Geliştirici Hesapları",
          icon: "manage_accounts",
          roles: adminRoles,
        },
        component: () => import("../views/PlatformAccounts/PlatformAccounts.vue"),
        children: [
          {
            path: "platform-account-list",
            name: "PlatformAccounts",
            props: true,
            meta: {
              requiresAuth: true,
              title: "Geliştirici Hesapları",
              icon: "manage_accounts",
              roles: adminRoles,
            },
            component: () => import("../views/PlatformAccounts/PlatformAccountsList.vue"),
          },
          {
            path: "platform-account",
            name: "PlatformAccount",
            props: true,
            meta: {
              requiresAuth: true,
              title: "Hesap Ekle",
              icon: "manage_accounts",
              roles: adminRoles,
            },
            component: () => import("../views/PlatformAccounts/PlatformAccount.vue"),
          },
          {
            path: "platform-account/:platform/:id",
            name: "PlatformAccountEdit",
            props: true,
            meta: {
              requiresAuth: true,
              title: "Hesap Güncelle",
              icon: "manage_accounts",
              roles: adminRoles,
            },
            component: () => import("../views/PlatformAccounts/PlatformAccount.vue"),
          },
        ]
      },
      {
        path: "notifications",
        redirect:"/notifications/notification-list",
        meta: {
          requiresAuth: true,
          title: "Bildirimler",
          icon: "notifications",
          roles: allRoles,
        },
        component: () => import("../views/Notification/Notifications.vue"),
        children: [
          {
            path: "notification-list",
            name: "Notifications",
            meta: {
              requiresAuth: true,
              title: "Bildirim Servisi",
              icon: "notifications",
              roles: allRoles,
            },
            component: () => import("../views/Notification/NotificationList.vue"),
          },
          {
            path: "notification",
            name: "Notification",
            meta: {
              requiresAuth: true,
              title: "Bildirim Servisi",
              icon: "notifications",
              roles: customerRoles,
            },
            component: () => import("../views/Notification/Notification.vue"),
          },
          {
            path: "notification/:id",
            name: "MobileNotification",
            meta: {
              requiresAuth: true,
              title: "Bildirim Servisi",
              icon: "notifications",
              roles: customerRoles,
            },
            component: () => import("../views/Notification/Notification.vue"),
          },
        ]
      }, 
      {
        path: "stores",
        redirect: "/stores/store-list",
        props: true,
        meta: {
          requiresAuth: true,
          title: "Mağazalar",
          icon: "store",
          roles: adminRoles,
        },
        component: () => import("../views/Store/Stores.vue"),
        children: [
          {
            path: "store-list",
            name: "StoreList",
            props: true,
            meta: {
              requiresAuth: true,
              title: "Mağazalar",
              icon: "store",
              roles: adminRoles,
            },
            component: () => import("../views/Store/StoreList.vue"),
          },
          {
            path: "store",
            name: "Store",
            props: true,
            meta: {
              requiresAuth: true,
              title: "Mağaza Ekle",
              icon: "store",
              roles: adminRoles,
            },
            component: () => import("../views/Store/Store.vue"),
          },
          {
            path: "store/:id",
            name: "StoreEdit",
            props: true,
            meta: {
              requiresAuth: true,
              title: "Mağaza Güncelle",
              icon: "store",
              roles: adminRoles,
            },
            component: () => import("../views/Store/Store.vue"),
          },
        ]
      },
      {
        path: "subscriptions",
        redirect: "/subscriptions/subscription-list",
        props: true,
        meta: {
          requiresAuth: true,
          title: "Üyelik",
          icon: "subscriptions",
          roles: adminRoles,
        },
        component: () => import("../views/Subscription/Subscriptions.vue"),
        children: [
          {
            path: "subscription-list",
            name: "SubscriptionList",
            props: true,
            meta: {
              requiresAuth: true,
              title: "Üyelik",
              icon: "subscriptions",
              roles: adminRoles,
            },
            component: () => import("../views/Subscription/SubscriptionList.vue"),
          },
        ]
      },
      {
        path: "password",
        name: "Password",
        props: true,
        meta: {
          requiresAuth: true,
          title: "Şifre Güncelle",
          icon: "lock",
          roles: allRoles,
        },
        component: () => import("../views/Profile/Password.vue"),
      },
      {
        path: "profile",
        name: "Profile",
        props: true,
        meta: {
          requiresAuth: true,
          title: "Profil",
          icon: "person",
          roles: allRoles,
        },
        component: () => import("../views/Profile/Profile.vue"),
      },
    ]
  },

  {
    path: "/notfound",
    name: "Notfound",
    component: () => import("../views/NotFound.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/notfound",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isLoggedIn = store.getters["auth/getIsLoggedIn"]
    if (!isLoggedIn) {
      next({ name: 'Login' });
    }
    else {
      const userRoleGroup = Object.values(store.getters["auth/getUser"].groups);
      const hasValidRole = to.meta.roles.some(role => userRoleGroup.includes(role));

      if (to.meta.requiresAuth) {
        if (hasValidRole) {
          next();
        } else {
          next("/notfound");
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});


export default router;