<template>
  <div class="table-wrapper">
    <div class="table-header">
      <h4 class="table-title">{{ tableTitle }}</h4>
      <div class="d-flex ml-auto">
        <SearchBox
          :searchKey="'all'"
          :data="data"
          :thead="thead"
          @search="onSearch"
          :placeholder="'Ara'"
          v-if="search"
        />
        <vue-excel-xlsx
          class="mr-10"
          :data="
            excelData.length > 0
              ? excelData
              : monthFiltred
              ? filtredDataAsMont
              : data
          "
          :thead="thead"
          :detailHead="detailHead"
          :detailData="
            excelDetailData.length > 0 ? excelDetailData : detailData
          "
          :fileName="tableTitle"
          :file-type="'xlsx'"
          :sheet-name="'sheetname'"
          :exportOptinal="exportOptinal"
          v-if="excel"
        />
        <slot name="button"></slot>
      </div>
    </div>
    <div class="table-container">
      <div class="table-body">
        <table class="table">
          <thead>
            <tr>
              <th v-if="excel">Hepsini Seç</th>
              <template v-for="item in thead" :key="item">
                <th v-if="item.value == 'icon'">
                  {{ item.label }}
                </th>
                <SortTable
                  @sortColumn="onSort"
                  :data="data"
                  :value="item.value"
                  :text="item.label"
                  v-else-if="item.sortable"
                />
                <th v-else>{{ item.label }}</th>
              </template>
            </tr>
            <tr v-if="searchBox">
              <th v-if="excel">
                <input
                  class="form-check-input excel-check-all"
                  type="checkbox"
                  @change="excelCheckAll($event)"
                />
              </th>
              <template v-for="searchBox in thead" :key="searchBox">
                <th v-if="searchBox.value == 'check'">
                  <div class="form-check ml-10">
                    <input
                      class="form-check-input check-all"
                      type="checkbox"
                      @change="checkAll($event)"
                    />
                  </div>
                </th>
                <th v-else-if="searchBox.searchable">
                  <SearchBox
                    :data="data"
                    @search="onSearch"
                    :searchKey="searchBox.value"
                    :placeholder="searchBox.label"
                  />
                </th>
                <th v-else></th>
              </template>
            </tr>
          </thead>
          <tbody>
            <template v-if="data == ''">
              <tr>
                <td>Gösterilecek işlem yok.</td>
              </tr></template
            >
            <template v-else-if="searched && manupulatedData.length == 0">
              <tr>
                <td>Eşleşen işlem yok</td>
              </tr>
            </template>
            <template v-if="monthFiltred">
              <template v-if="filtredDataAsMont.length > 0">
                <template v-for="(item, i) in filtredDataAsMont" :key="i">
                  <tr v-if="i < pageNumber && i >= pageNumber - showDataCount">
                    <td v-if="excel">
                      <input
                        type="checkbox"
                        class="form-check-input excel-check"
                        :value="item.id"
                        @change="addExcell(item.id)"
                      />
                    </td>
                    <template v-for="(cell, i) in thead" :key="i">
                      <td class="text-center">
                        <slot :name="`cell-${cell.value}`" :row="item">
                          {{ item[prop] }}
                        </slot>
                      </td>
                    </template>
                  </tr>
                </template>
              </template>
              <template v-else>
                <tr>
                  <td>Eşleşen işlem yok</td>
                </tr>
              </template>
            </template>
            <template v-for="(item, i) in manupulatedData" :key="i" v-else>
              <tr v-if="i < pageNumber && i >= pageNumber - showDataCount">
                <td v-if="excel">
                  <input
                    type="checkbox"
                    class="form-check-input excel-check"
                    :value="item.id"
                    @change="addExcell(item.id)"
                  />
                </td>
                <template v-for="(cell, i) in thead" :key="i">
                  <td class="text-center">
                    <slot :name="`cell-${cell.value}`" :row="item">
                      {{ item[prop] }}
                    </slot>
                  </td>
                </template>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <template v-if="true">
        <div class="table-preloader">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </template>
    </div>
    <nav class="paginate">
      <ul class="pagination">
        <li class="page-item">
          <button
            class="page-link"
            :disabled="pageNumber > showDataCount ? false : true"
            @click="setPrev"
            type="button"
          >
            <i class="icon icon-arrow-left-2"></i>
          </button>
        </li>
        <template v-if="searched">
          <template
            v-for="item in manupulatedData.length % showDataCount == 0
              ? Math.floor(manupulatedData.length / showDataCount)
              : Math.floor(manupulatedData.length / showDataCount + 1)"
            :key="item"
          >
            <li class="page-item" :class="[item == 1 ? 'has-active' : '']">
              <button
                :data-index="item"
                @click="setPagination(item * showDataCount)"
                class="page-link"
                :class="[item == 1 ? 'active' : '']"
              >
                {{ item }}
              </button>
            </li>
          </template>
          <li>
            <button
              class="page-link"
              v-if="pageNumber < manupulatedData.length"
              @click="setNext"
            >
              <i class="icon icon-arrow-right-1"></i>
            </button>
          </li>
        </template>
        <template v-else>
          <template
            v-for="item in data.length % showDataCount == 0
              ? Math.floor(data.length / showDataCount)
              : Math.floor(data.length / showDataCount + 1)"
            :key="item"
          >
            <li class="page-item" :class="[item == 1 ? 'has-active' : '']">
              <button
                :data-index="item"
                @click="setPagination(item * showDataCount)"
                class="page-link"
                :class="[item == 1 ? 'active' : '']"
              >
                {{ item }}
              </button>
            </li>
          </template>
        </template>
        <li>
          <button
            class="page-link"
            type="button"
            :disabled="
              (searched && manupulatedData.lenght == pageNumber) ||
              (!searched && pageNumber < data.length)
                ? false
                : true
            "
            @click="setNext"
          >
            <i class="icon icon-arrow-right-3"></i>
          </button>
        </li>
      </ul>
    </nav>
    <button
      class="btn btn-empty-grey-600 uncheckSelected"
      @click="uncheckSelected"
      v-if="excel"
    >
      Seçimi Temizle
      <i class="icon icon-trash ml-10"></i>
    </button>
  </div>
  {{ watched }}
</template>
<script>
import SearchBox from "./SearchBox.vue";
import SortTable from "./SortTable.vue";
import VueExcelXlsx from "./VueExcelXlsx.vue";

export default {
  name: "Table",
  components: {
    SearchBox,
    VueExcelXlsx,
    SortTable,
  },
  data() {
    return {
      searchKey: "",
      tableData: [],
      sortedData: [],
      excelData: [],
      excelDetailData: [],
      searched: false,
      showColletBtn: false,
      showDataCount: 50,
      pageNumber: 50,
      manupulatedData: [],
      filtredDataAsMont: [],
      selectedDate: "",
      monthFiltred: false,
    };
  },
  props: {
    data: Array,
    thead: Array,
    detailHead: Array,
    detailData: Array,
    search: Boolean,
    excel: Boolean,
    searchBox: Boolean,
    tableTitle: String,
    exportOptinal: Boolean,
    preloader: Boolean,
  },
  created() {
    this.pageNumber = this.showDataCount;
    this.searched = false;
  },

  watch: {
    pageNumber: function () {
      this.setData();
    },
    selectedDate: function () {
      this.selectedDate == ""
        ? (this.monthFiltred = false)
        : (this.monthFiltred = true);
      this.filterDataAsSelectedDate();
    },
  },
  computed: {
    watched() {
      this.setData();
    },
  },
  methods: {
    filterDataAsSelectedDate() {
      this.filtredDataAsMont = [];
      if (this.selectedDate) {
        let month =
          this.selectedDate.month < 9
            ? "0" + (this.selectedDate.month + 1)
            : String(this.selectedDate.month + 1);
        let year = this.selectedDate.year;
        this.manupulatedData.filter((item) => {
          if (item.installments.length > 0) {
            item.installments.forEach((installment) => {
              let installmentDate =
                installment.installmentDate.slice(3, 5) +
                installment.installmentDate.slice(6, 10);
              let installmentStatus = installment.installmentStatus;

              if (
                installmentDate == month + year &&
                installmentStatus != "Ödenmedi"
              ) {
                this.filtredDataAsMont.push(item);
              }
            });
          }
        });
      } else {
        this.monthFiltred = false;
      }
    },
    uncheckSelected() {
      let checkboxes = document.querySelectorAll(".excel-check");
      let allCheckBox = document.querySelector(".excel-check-all");
      checkboxes.forEach((checkbox) => (checkbox.checked = false));
      allCheckBox.checked = false;
    },
    setData() {
      if (!this.searched) {
        this.manupulatedData = this.data;
      }
      setTimeout(() => {
        document.querySelector(".table-preloader")
          ? document.querySelector(".table-preloader").remove()
          : "";
      }, 2000);
    },
    addExcell(value) {
      let row = this.manupulatedData.find((item) => item.id == value);
      const index = this.excelData.findIndex(
        (containsItem) => containsItem.id === value
      );

      if (index !== -1) {
        this.excelData.splice(index, 1);
      } else {
        this.excelData.push(row);
      }

      let checkboxes = document.querySelectorAll(".excel-check");
      let excelAllCheck = document.querySelector(".excel-check-all");

      this.excelDetailData = [];

      checkboxes.forEach((checkbox) => {
        if (checkbox.checked) {
          if (this.detailData) {
            this.detailData.filter((item) => {
              if (item.id == checkbox.value) {
                this.excelDetailData.push(item);
              }
            });
          }
        }
        checkbox.addEventListener("change", () => {
          let checkAllCheckbox = Array.prototype.every.call(
            checkboxes,
            (chk) => chk.checked
          );

          if (checkAllCheckbox) {
            excelAllCheck.checked = true;
          } else {
            excelAllCheck.checked = false;
          }
        });
      });
    },
    excelCheckAll(e) {
      let checkboxes = document.querySelectorAll(".excel-check");
      checkboxes.forEach((checkbox) => {
        if (!e.target.checked) {
          this.excelData = [];
          checkbox.checked = false;
        } else {
          if (!checkbox.checked) {
            checkbox.checked = true;
            this.addExcell(checkbox.value);
          }
        }
      });
    },
    onSearch(value) {
      this.pageNumber = this.showDataCount;
      value.length == this.data.length
        ? (this.searched = false)
        : (this.searched = true);
      this.manupulatedData = value;
    },
    onSort(value) {
      this.manupulatedData = value;
    },
    setPrev() {
      this.pageNumber -= this.showDataCount;
      this.setActiveButton();
    },
    setNext() {
      this.pageNumber += this.showDataCount;
      this.setActiveButton();
    },
    setActiveButton() {
      let buttons = document.querySelectorAll(".page-link");
      buttons.forEach((button) => {
        if (
          button.getAttribute("data-index") ==
          this.pageNumber / this.showDataCount
        ) {
          button.classList.add("active");
          button.parentNode.classList.add("has-active");
        } else {
          button.classList.remove("active");
          button.parentNode.classList.remove("has-active");
        }
      });
    },
    setPagination(value) {
      this.pageNumber = value;
      this.setActiveButton();
    },
    checkAll(e) {
      let table = document.querySelector(".table-wrapper");
      let checkboxes = table.querySelectorAll(".channel-checkbox");
      let assignCheckboxes = table.querySelectorAll(".assign-checkbox");
      checkboxes.forEach((checkbox) => {
        if (e.target.checked) {
          checkbox.checked ? "" : checkbox.click();
        } else {
          checkbox.checked ? checkbox.click() : "";
        }
      });
      assignCheckboxes.forEach((checkbox) => {
        if (e.target.checked) {
          checkbox.checked ? "" : checkbox.click();
        } else {
          checkbox.checked ? checkbox.click() : "";
        }
      });
    },
  },
};
</script>
<style scoped>
.removeElem {
  display: none;
}
.table-wrapper {
  position: relative;
}
</style>