import axios from 'axios';
import curlirize from 'axios-curlirize';
import env from '../sys.config.js'
import store from '../store'

// curlirize(axios);

axios.interceptors.request.use(
    config => {
        //console.log(config.url, config);
        return {
            ...config,
            headers: {
                Authorization: "Bearer " + store.state.auth.access_token,
            },
        };
    },
    error => console.log('req-error', error)
);
axios.interceptors.response.use(
    response => {
        //console.log(response.config.url, response); // Yanıt verisini yazdırma
        return response; // Yanıtı başarıyla dönme
    },
    error => {
        return Promise.reject(error);
    }
);
axios.defaults.baseURL = env.stage.API_BASE_URL + env.version;

export default axios