function removeHttp(value) {
    value.toLowerCase();
    const pattern = /[ı,İ,ö,Ö,ü,Ü,ç,Ç,ş,Ş,ğ,Ğ, ,]/;
    value.includes("://")
        ? (value = value.split("://")[1])
        : value;

    if (pattern.test(value)) {
        return (value = value
            .split("")
            .filter((item) => !item.match(pattern))
            .map((i) => i)
            .join(""));
    }
    return value
}
function replaceUrl(url) {
    url.slice(url.length - 1) == "/"
        ? (url = url.slice(0, url.length - 1))
        : "";
    return url;
}
function removeSpace(item) {
    if (item != undefined) {
        item = item.trimStart();
        return item;
    }
    else {
        return item = ""
    }
}

function downloadFile(url) {
    const mathRandom = Math.floor(1000000000 + Math.random() * 9000000000);
    fetch(url)
        .then((res) => res.blob())
        .then((file) => {
            let tempUrl = URL.createObjectURL(file);
            const aTag = document.createElement("a");
            aTag.href = tempUrl;
            aTag.download = url.replace(/^.*[\\\/]/, "") + '?file=' +  mathRandom;
            document.body.appendChild(aTag);
            aTag.click();
            URL.revokeObjectURL(tempUrl);
            aTag.remove();
        });
}

function setCookie(cname, cvalue) {
    document.cookie = cname + "=" + cvalue + ";";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }



export { removeHttp, replaceUrl, removeSpace, downloadFile, setCookie, getCookie }