<template>
  <router-view />
</template>

<script>

export default {
  name: "App",
  created(){
    let mode = this.getCookie("theme-mode")
    if (mode){
      this.$store.state.theme.theme_mode = mode
      document.querySelector("body").classList.add(mode);
    }
  }
};
</script>
<style lang="scss">
@import "./assets/scss/app.scss";
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>